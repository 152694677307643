<template>
  <section>
    <p class="text-h5 text-center red--text">{{ errorMessage }}</p>

    <div class="d-flex justify-center">
      <base-btn class="my-2 w-auto" variant="plain" @click="retryFetchAction">
        {{ $t("retryFetchAction") }}
      </base-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: "ErrorRetryActionComponent",
  props: {
    errorMessage: { type: String, required: true },
    retryFetchAction: { type: Function, required: true },
  },
};
</script>
